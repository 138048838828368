function Footer()
{
    const element = (
        <div className="mt-3 text-center ">
            <div className="text-wrap">
            2024 - Present Rishabh Verma. All Rights Reserved
            </div>
           
        </div>  
    );

    return element;
}
export default Footer;